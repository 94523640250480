import { environment } from '@environments/environment';
import { EnumUserRole } from '@app/shared/constants/user.enum';

/*
Returns an array listing sidebar menu items.

Menu item params:
@param text:        string (optional)       Menu label
@param icon:        string (optional)       Display an icon with the label. Must be an icon alias
@param link:        string (optional)       Internal route link
@param alert:       string (optional)       Display a badge
@param label:       string (optional)       Badge's class. Default value: 'badge badge-success'
@param heading:     boolean (optional)      If true, display the item as a section heading
@param submenu:     array (optional)        List of children items
@param position:    array (optional)        'right' value if icon on the right

*/

// *************  Header Nav ************* //
const HeaderNavigation = [
    {
        text: 'map.actions.show-map',
        link: '/map',
        icon: 'map'
    }
];

export const headerMenu = HeaderNavigation;

// *************  Hub Nav ************* //
const HubNavigation = [
    {
        text: 'app.text.my-vertuoz',
        heading: true
    },
    {
        text: 'app.extern-links.hub',
        elink: environment.extLinks.portal,
        icon: 'apps'
    },
    {
        text: 'app.extern-links.administration',
        elink: environment.extLinks.portalAdministration,
        icon: 'settings',
        roles: [EnumUserRole.adminGlobal]
    },
    {
        text: 'app.text.links',
        heading: true
    },
    {
        text: 'app.extern-links.vertuoz',
        elink: 'https://www.siradel.com/fr/solutions/villes/logiciel-suivi-energetique/',
        icon: 'arrow_right_alt',
        position: 'right',
        target: '_blank'
    },
    {
        text: 'app.extern-links.news',
        elink: 'https://www.siradel.com/fr/new-articles/',
        icon: 'arrow_right_alt',
        position: 'right',
        target: '_blank'
    }
];

export const hubMenu = HubNavigation;

// *************  Hub Nav ************* //
const BulkActionNavigation = [];

export const bulkMenu = BulkActionNavigation;
