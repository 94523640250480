<ul class="hub-menu pb-3">
  <li *ngFor="let item of hubMenu; let first = first">
    <ng-container *hasRole="item.roles">
      <strong
        class="d-block px-4 pt-3 pb-2"
        [ngClass]="!first ? 'border-top mt-3' : ''"
        *ngIf="item.heading"
        >{{ item.text | translate }}</strong
      >
      <a
        *ngIf="!item.heading && !item.submenu"
        class="px-4 font-14"
        [ngClass]="!item.position ? 'd-block mb-2' : ''"
        [attr.routerLink]="item.link"
        [attr.target]="item.target"
        [attr.href]="item.elink"
        title="{{ item.text }}"
      >
        <ng-container *ngIf="!item.position">
          <mat-icon *ngIf="item.icon" class="me-2 mb-1">{{ item.icon }}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" svgIcon="{{ item.svgIcon }}" class="me-2 mb-1"></mat-icon>
        </ng-container>
        <span>{{ item.text | translate }}</span>
        <ng-container *ngIf="item.position === 'right'">
          <mat-icon *ngIf="item.icon" class="ms-2 mb-1">{{ item.icon }}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" svgIcon="{{ item.svgIcon }}" class="ms-2 mb-1"></mat-icon>
        </ng-container>
      </a>
    </ng-container>
  </li>
</ul>
