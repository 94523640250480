import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { BaseService } from '@app/core/http/base.service';
import { PageResult } from '@app/core/models/common/page-result.model';
import { PermissionService } from '@app/core/services/permission.service';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { ToolsService } from '@app/features/shared/services/tools.service';
import { FileExportType } from '@app/shared/constants/export.enum';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { environment } from '@environments/environment';
import {
    DeviceFilter,
    DeviceModel,
    PredicateDeviceDto,
    SensorFilter,
    SensorModel
} from '@features/device/models/models';

@Injectable({
    providedIn: 'root'
})
export class DeviceService extends BaseService {
    public loading: boolean;
    public dataSource: BehaviorSubject<GridDataResult>;
    public predicateDeviceDto: PredicateDeviceDto = <PredicateDeviceDto>{
        activePerimeter: true
    };
    API_ENDPOINT: string = environment.API_ENDPOINT;

    /**
     * Constructeur
     * @param http service http
     * @param permissionService
     * @param toolsService
     */
    constructor(
        protected http: HttpClient,
        protected permissionService: PermissionService,
        private toolsService: ToolsService
    ) {
        super();
        this.dataSource = new BehaviorSubject<GridDataResult>(null);
    }

    /**
     * @param predicateDeviceDto l'objet device en cours
     */
    getDeviceList(predicateDeviceDto: PredicateDeviceDto): Observable<PageResult<DeviceModel>> {
        this.loading = true;
        const headers = this.permissionService.getHeader();

        /** Filtre page */
        const pagedContext = new PagedContext();
        pagedContext.pageSize = predicateDeviceDto.pageSize;
        pagedContext.currentPage = this.getCurrentPage(
            predicateDeviceDto.page,
            predicateDeviceDto.pageSize
        );
        pagedContext.sortField = predicateDeviceDto.sortField;
        pagedContext.sortDesc = predicateDeviceDto.sort;

        /** Filtre device */
        let deviceFilter = new DeviceFilter();
        deviceFilter.deviceLabel = predicateDeviceDto.libelle;
        deviceFilter.deviceLocalisation = predicateDeviceDto.localisation;
        deviceFilter.deviceSupplierId = predicateDeviceDto.IDFournisseur;
        deviceFilter.deviceStatus = predicateDeviceDto.status;
        deviceFilter.lastReadingDateMax = predicateDeviceDto.lastMessageDate;
        deviceFilter.deviceIds = predicateDeviceDto.deviceIds;

        /** Filtre sensor */
        const sensorFilter = new SensorFilter();
        sensorFilter.sensorMeasureTypeId = predicateDeviceDto.typeMesureId
            ? [predicateDeviceDto.typeMesureId]
            : null;

        const queryStrPagedContext = this.toolsService.transformParamsToQueryString(pagedContext);
        const queryStrDeviceFilter = this.toolsService.transformParamsToQueryString(deviceFilter);
        const queryStrSensorFilter = this.toolsService.transformParamsToQueryString(sensorFilter);

        let queryStr = '';
        if (queryStrPagedContext !== '') {
            queryStr += '&' + queryStrPagedContext;
        }
        if (queryStrDeviceFilter !== '') {
            queryStr += '&' + queryStrDeviceFilter;
        }
        if (queryStrSensorFilter !== '') {
            queryStr += '&' + queryStrSensorFilter;
        }
        /** Filtre perimetre actif */
        if (predicateDeviceDto.activePerimeter) {
            queryStr += '&perimeterScope=' + PerimeterScope.activeSelectedPerimeter;
        } else {
            queryStr += '&perimeterScope=' + PerimeterScope.activeGeographicRight;
        }

        return this.http
            .get<PageResult<DeviceModel>>(`${this.API_ENDPOINT}Devices${'?' + queryStr}`, {
                headers
            })
            .pipe(
                mergeMap(devices => {
                    if (devices === null) {
                        this.loading = false;
                        // tslint:disable-next-line: deprecation
                        return of(null);
                    }
                    deviceFilter = new DeviceFilter();
                    deviceFilter.deviceIds = [];
                    devices.results.forEach(d => deviceFilter.deviceIds.push(d.deviceId));
                    return this.http
                        .get<PageResult<SensorModel>>(
                            `${this.API_ENDPOINT}Sensors${'?' +
                                this.toolsService.transformParamsToQueryString(deviceFilter)}`,
                            {
                                headers
                            }
                        )
                        .pipe(
                            map(sensor => {
                                devices.results.forEach(d => {
                                    d.sensors = [];
                                    const t = sensor.results.filter(s => s.deviceId === d.deviceId);
                                    d.sensors.push(...t);
                                });
                                this.loading = false;
                                return devices;
                            })
                        );
                })
            );
    }

    /**
     * Get the device features
     * @param id the id of the device
     */
    public getDevice(id: number): Observable<DeviceModel> {
        const headers = this.permissionService.getHeader();
        return this.http.get<DeviceModel>(`${this.API_ENDPOINT}Devices/${id}?detailLevel=9`, {
            headers
        });
    }
    fcCouleur;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public updateDevice(device: DeviceModel) {
        return this.http.put<DeviceModel>(`${this.API_ENDPOINT}Devices/${device.deviceId}`, device);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public createDevice(device: DeviceModel) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.post<any>(`${this.API_ENDPOINT}Devices/`, device);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public deleteDevice(id: number) {
        const headers = this.permissionService.getHeader();
        return this.http.delete(`${this.API_ENDPOINT}Devices/${id}`, { headers });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public deleteDevices(selection: number[]) {
        const headers = this.permissionService.getHeader();
        let queryStr = '';

        for (let i = 0, len = selection.length; i < len; i++) {
            if (i === 0) {
                queryStr += `?ids=${selection[i]}`;
            } else {
                queryStr += `&ids=${selection[i]}`;
            }
        }
        return this.http.delete(`${this.API_ENDPOINT}Devices${queryStr}`, { headers });
    }

    /**
     * retourne la valeur current selectionné
     * @param data prend skip
     */
    private getCurrentPage(data: number, pageSize: number): number {
        return Math.floor(data / pageSize) + 1;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public isDeviceLabelExist(label: string, excludedDeviceId?: number) {
        const headers = this.permissionService.getHeader();
        return this.http.get<Boolean>(
            `${this.API_ENDPOINT}Devices/LabelExists?label=` +
                label +
                (excludedDeviceId !== null ? '&excludedDeviceId=' + excludedDeviceId : ''),
            {
                headers
            }
        );
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public isDeviceSupplierIdExist(supplierId: string, excludedDeviceId?: number) {
        const headers = this.permissionService.getHeader();
        return this.http.get<Boolean>(
            `${this.API_ENDPOINT}Devices/SupplierIdExists?supplierId=` +
                supplierId +
                (excludedDeviceId !== null ? '&excludedDeviceId=' + excludedDeviceId : ''),
            { headers }
        );
    }

    public getDevicesAndSensorsAsFile(
        predicateDeviceDto: PredicateDeviceDto,
        fileType: FileExportType
    ): Observable<HttpResponse<Object>> {
        /** Filtre device */
        const deviceFilter = <DeviceFilter>{
            deviceIds: predicateDeviceDto.deviceIds,
            deviceLabel: predicateDeviceDto.libelle,
            deviceLocalisation: predicateDeviceDto.localisation,
            deviceSupplierId: predicateDeviceDto.IDFournisseur,
            deviceStatus: predicateDeviceDto.status,
            lastReadingDateMax: predicateDeviceDto.lastMessageDate
        };

        /** Filtre sensor */
        const sensorFilter = <SensorFilter>{
            sensorMeasureTypeId: predicateDeviceDto.typeMesureId
                ? [predicateDeviceDto.typeMesureId]
                : null
        };

        const activePerimeter = { activePerimeter: predicateDeviceDto.activePerimeter };

        const fileTypeFilter = { fileType: fileType };

        const qry = this.toolsService.transformParamsArrayToQueryString(
            deviceFilter,
            sensorFilter,
            activePerimeter,
            fileTypeFilter
        );

        const url = `${this.API_ENDPOINT}Devices/FileDatas?${qry}`;
        return this.http.get(url, {
            responseType: 'blob' as 'json',
            observe: 'response'
        });
    }
}
