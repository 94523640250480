import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AppService } from '@app/core/http/app.service';

@Directive({
    selector: '[hasRole]'
})
export class HasRoleDirective {
    // tslint:disable-next-line: no-use-before-declare
    private _context: HasRoleContext = new HasRoleContext();
    private _thenTemplateRef: TemplateRef<HasRoleContext> | null = null;
    private _elseTemplateRef: TemplateRef<HasRoleContext> | null = null;
    private _thenViewRef: EmbeddedViewRef<HasRoleContext> | null = null;
    private _elseViewRef: EmbeddedViewRef<HasRoleContext> | null = null;

    constructor(
        private _viewContainer: ViewContainerRef,
        templateRef: TemplateRef<HasRoleContext>,
        private appService: AppService
    ) {
        this._thenTemplateRef = templateRef;
    }

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set hasRole(condition: any) {
        const userRole = this.appService.getUserContext().role.id;

        if (condition == null || condition.length == 0) {
            this._context.$implicit = true;
        } else {
            this._context.$implicit = this._context.hasRole =
                userRole && condition.includes(userRole);
        }

        this._updateView();
    }

    @Input()
    set hasRoleThen(templateRef: TemplateRef<HasRoleContext>) {
        this._thenTemplateRef = templateRef;
        this._thenViewRef = null; // clear previous view if any.
        this._updateView();
    }

    @Input()
    set hasRoleElse(templateRef: TemplateRef<HasRoleContext>) {
        this._elseTemplateRef = templateRef;
        this._elseViewRef = null; // clear previous view if any.
        this._updateView();
    }

    private _updateView(): void {
        if (this._context.$implicit) {
            if (!this._thenViewRef) {
                this._viewContainer.clear();
                this._elseViewRef = null;
                if (this._thenTemplateRef) {
                    this._thenViewRef = this._viewContainer.createEmbeddedView(
                        this._thenTemplateRef,
                        this._context
                    );
                }
            }
        } else {
            if (!this._elseViewRef) {
                this._viewContainer.clear();
                this._thenViewRef = null;
                if (this._elseTemplateRef) {
                    this._elseViewRef = this._viewContainer.createEmbeddedView(
                        this._elseTemplateRef,
                        this._context
                    );
                }
            }
        }
    }
}

/**
 * @stable
 */
export class HasRoleContext {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public $implicit: any = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public hasRole: any = null;
}
