import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { MatOption } from '@angular/material/core';
import { PagedContext } from '@app/features/shared/models/paged-context';
// generic items model
export interface ItemModel {
    itemLabel: string;
    itemId: number;
    itemClientReference?: string;
    itemMetadata?: ItemMetaDataModel[];
    itemLevelId?: number;
    isGroup?: boolean;
    itemParentId?: number;
    isSelected?: boolean;
}

export interface ItemMetaDataModel {
    labelMetaData: string;
    textMetaData: string;
}

// fetching data params model
export interface FetchDataParamsModel {
    formControlName?: string;
    searchKey: string;
    paging: PagedContext;
    isScrolling: boolean;
}

@Component({
    selector: 'vertuoz-autocomplete-select',
    templateUrl: './vertuoz-autocomplete-select.component.html',
    styleUrls: ['./vertuoz-autocomplete-select.component.scss']
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class VertuozAutocompleteSelectComponent implements OnInit, OnChanges, OnDestroy, DoCheck {
    // component inputs
    @Input() optionTemplateStyle: 'default' | 'multiLevels' = 'default';
    @Input() items: Array<ItemModel>;
    @Input() selectedItem: ItemModel;
    @Input() placeholder = 'option';
    @Input() disabledControl: boolean;
    @Input() showClientReference = false;
    @Input() remoteMode = false;

    // Multiple selection
    @Input() isMultiple = false;
    @Input() selectedItems: Array<ItemModel> = [];

    @Input() resetCurentPageEvent: Observable<number>;
    @Input() stopScrollEvent: Observable<void>;
    @Input() pageSize = 10;
    // component output
    @Output() selectionChanged: EventEmitter<ItemModel> = new EventEmitter<ItemModel>();
    @Output() fetchData: EventEmitter<FetchDataParamsModel> = new EventEmitter<
        FetchDataParamsModel
    >();
    @Output() selectionMultipleChanged: EventEmitter<ItemModel[]> = new EventEmitter<ItemModel[]>();
    // local vars
    resetCurentPageTo = 1;
    itemCtrl = new FormControl();
    filteredItems: Array<ItemModel>;

    filteredItemsSelected: Array<ItemModel> = [];
    filteredItemsNotSelected: Array<ItemModel> = [];
    searchMode = false;

    isLoadingFetch = true;
    isLoadingScroll = false;
    isEndOfScroll = this.remoteMode ? false : true;
    currentPaging = new PagedContext({
        currentPage: this.resetCurentPageTo,
        pageSize: this.pageSize
    });

    iterableDiffer: IterableDiffer<ItemModel>;
    iterableDifferSelection: IterableDiffer<ItemModel>;

    /** enregistrement pour desouscrire */
    private _onDestroy = new Subject<void>();

    constructor(private iterableDiffers: IterableDiffers) {
        this.iterableDiffer = this.iterableDiffers.find([]).create(null);
        this.iterableDifferSelection = this.iterableDiffers.find([]).create(null);
    }

    // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
    ngOnInit(): void {
        if (this.disabledControl) {
            this.itemCtrl.disable();
        } else {
            this.itemCtrl.enable();
        }

        if (this.resetCurentPageEvent) {
            this.resetCurentPageEvent.pipe(takeUntil(this._onDestroy)).subscribe(pageNumber => {
                this.resetCurentPageTo = pageNumber;
                this.currentPaging.currentPage = pageNumber;
                this.isLoadingFetch = true;
                this.isEndOfScroll = false;
                this.fetchData.emit({
                    searchKey: this.itemCtrl.value,
                    paging: this.currentPaging,
                    isScrolling: false
                } as FetchDataParamsModel);
            });
        }

        if (this.stopScrollEvent) {
            this.stopScrollEvent.pipe(takeUntil(this._onDestroy)).subscribe(() => {
                this.isEndOfScroll = true;
                this.isLoadingScroll = false;
                this.isLoadingFetch = false;
            });
        }

        this.itemCtrl.valueChanges
            .pipe(
                takeUntil(this._onDestroy),
                startWith(''),
                distinctUntilChanged(),
                debounceTime(400),
                map(item => {
                    // On vides les option filtré
                    this.filteredItems = [];
                    this.filteredItemsNotSelected = [];
                    if (typeof item === 'string' && item !== '') {
                        this.searchMode = true;
                        if (this.filteredItemsSelected) {
                            this.filteredItemsSelected = this.filteredItemsSelected.filter(
                                e =>
                                    this.getLabelFormatted(e)
                                        .toLowerCase()
                                        .indexOf(item) > -1
                            );
                        }
                    } else {
                        this.searchMode = false;
                        this.filteredItemsSelected = this.selectedItems.slice();
                    }

                    // on place le loader
                    this.isLoadingFetch = true;
                    if (this.remoteMode) {
                        this.isEndOfScroll = false;
                    }
                    // On créer la liste filtré
                    return item ? this._filterItems(item) : this.items.slice();
                })
            )
            .subscribe(items => {
                if (!items) {
                    this.isLoadingFetch = false;
                }
                // Cas du remote => on déporte l'action a l'extérieur du composant
                //      - au retour de l'action il faudra mettre le loader a false
                //      - L'action extérieur devra modifier la liste
                else if (this.remoteMode) {
                    this.currentPaging.currentPage = 1;
                    this.fetchData.emit({
                        searchKey: this.itemCtrl.value,
                        paging: this.currentPaging,
                        isScrolling: false
                    } as FetchDataParamsModel);
                }
                // l'action est interne au conposant. On remet le loader a false et on charge les items filtré
                else {
                    this.filteredItems = items;
                    this.filteredItemsNotSelected = items;
                    this.isLoadingFetch = false;
                }
            });
    }

    // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
    ngDoCheck(): void {
        const changes = this.iterableDiffer.diff(this.items);
        const changesSelectedItems = this.iterableDifferSelection.diff(this.selectedItems);

        if (changesSelectedItems) {
            if (this.selectedItems) {
                changesSelectedItems.forEachRemovedItem(ri => {
                    if (this.filteredItemsSelected.map(si => si.itemId).includes(ri.item.itemId)) {
                        this.filteredItemsSelected.splice(
                            this.filteredItemsSelected.findIndex(i => i.itemId === ri.item.itemId),
                            1
                        );
                    }
                });

                changesSelectedItems.forEachAddedItem(ai => {
                    if (!this.filteredItemsSelected.map(si => si.itemId).includes(ai.item.itemId)) {
                        this.filteredItemsSelected.push(ai.item);
                    }
                });
            }
        }

        if (changes) {
            this.isLoadingScroll = false;
            this.isLoadingFetch = false;
            if (this.filteredItems || this.filteredItemsNotSelected) {
                if (this.filteredItems == null) {
                    this.filteredItems = [];
                }

                changes.forEachRemovedItem(ri => {
                    if (!this.isMultiple) {
                        this.filteredItems.splice(
                            this.filteredItems.findIndex(i => i.itemId === ri.item.itemId),
                            1
                        );
                    } else {
                        if (
                            this.filteredItemsNotSelected
                                .map(si => si.itemId)
                                .includes(ri.item.itemId)
                        ) {
                            this.filteredItemsNotSelected.splice(
                                this.filteredItemsNotSelected.findIndex(
                                    i => i.itemId === ri.item.itemId
                                ),
                                1
                            );
                        }
                    }
                });

                changes.forEachAddedItem(ai => {
                    if (!this.isMultiple) {
                        this.filteredItems.push(ai.item);
                    } else {
                        if (
                            !this.filteredItemsNotSelected
                                .map(si => si.itemId)
                                .includes(ai.item.itemId)
                        ) {
                            this.filteredItemsNotSelected.push(ai.item);
                        }
                    }
                });
            }
        }
    }

    // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedItem && changes.selectedItem.currentValue) {
            if (this.selectedItem && this.selectedItem.itemId != null) {
                this.itemCtrl.patchValue(this.selectedItem);
            }
        }

        if (changes.selectedItem && !changes.selectedItem.currentValue) {
            this.itemCtrl.reset();
        }

        if (changes.disabledControl) {
            if (changes.disabledControl.currentValue) {
                this.itemCtrl.disable();
            } else {
                this.itemCtrl.enable();
            }
        }

        if (changes.items && changes.items.currentValue) {
            if (!changes.items.isFirstChange()) {
                this.isLoadingFetch = false;
            }
        }

        if (changes.pageSize && changes.pageSize.currentValue) {
            this.currentPaging.pageSize = changes.pageSize.currentValue;
            this.currentPaging.currentPage = this.resetCurentPageTo;
        }
    }

    public onScroll(): void {
        if (!this.isEndOfScroll) {
            // avance la pagination
            this.currentPaging.currentPage++;
            // met le loader de scrolling a true
            this.isLoadingScroll = true;
            this.fetchData.emit({
                searchKey: this.itemCtrl.value,
                paging: this.currentPaging,
                isScrolling: true
            } as FetchDataParamsModel);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _filterItems(value: any): Array<ItemModel> {
        if (typeof value === 'string') {
            if (value === '') {
                return this.items;
            }
            const filterValue = value.toLowerCase();
            return this.items.filter(
                item =>
                    this.getLabelFormatted(item)
                        .toLowerCase()
                        .indexOf(filterValue) > -1
            );
        }
    }

    displayFn(item: ItemModel): string {
        return item ? item.itemLabel : '';
    }

    onSelectionChange($event: MatOption): void {
        this.selectionChanged.emit($event.value);
    }

    //#region Multiple

    removeChip = (data: ItemModel): void => {
        this.itemCtrl.patchValue('');
        this.toggleSelection(data);
    };

    optionClicked = (event: Event, item: ItemModel): void => {
        event.stopPropagation();
        this.toggleSelection(item);
    };

    toggleSelection = (data: ItemModel): void => {
        data.isSelected = data.isSelected === undefined ? false : !data.isSelected;
        if (data.isSelected === true) {
            this.selectedItems.push(data);
        } else {
            this.selectedItems = this.selectedItems.filter(e => e.itemId !== data.itemId)
                ? this.selectedItems.filter(e => e.itemId !== data.itemId)
                : [];
        }

        this.filteredItemsNotSelected = this.items.filter(
            e => !this.selectedItems.map(si => si.itemId).includes(e.itemId)
        );

        this.selectionMultipleChanged.emit(this.selectedItems);
    };

    //#endregion

    resetCurrentSelection(): void {
        if (
            this.itemCtrl.value &&
            (!this.itemCtrl.value.itemLabel || !this.itemCtrl.value.itemId)
        ) {
            this.selectionChanged.emit(null);
        } else if (this.itemCtrl.value === '') {
            this.itemCtrl.patchValue(null);
            this.selectionChanged.emit(null);
        }
    }

    getLabelFormatted(item: ItemModel): string {
        if (
            this.showClientReference &&
            item.itemClientReference &&
            item.itemClientReference.length > 0
        ) {
            return `${item.itemLabel} (${item.itemClientReference})`;
        } else {
            return item.itemLabel;
        }
    }
}
