import { DomainEnum } from '@app/shared/constants/domain.enum';
import { sensorMeasureType } from '@app/shared/constants/sensor-measure-types.enum';

export class GroupFilter {
    groupExactName?: string;
    filterByUserId?: number[];
    filterByGroupTerm?: string;
    filterbyLevelId?: number;
    filterByDomainIds?: DomainEnum[];
    FilterByPerimeterId?: number;
    groupPerimeterIds?: number[];
    hasObjective?: boolean;
    isSubjectToTertiaryDecree?: boolean;

    hasSensors?: boolean;
    sensorMeasureTypeIds?: sensorMeasureType[];

    constructor(init: Partial<GroupFilter>) {
        Object.assign(this, init);
    }
}
